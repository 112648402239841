<template>
  <div id="journeys-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link to="/journeys" class="btn btn-secondary mb-5 mr-3">Zurück zur Übersicht</router-link>
    <button @click="save" class="btn btn-primary mb-5">Customer Journey speichern</button>

    <!--<p>Todo:</p>
    <ul>
      <li>Make Customer Journey Module as main unit with Link Tree Pages as Journey steps</li>
      <li>Be able to name Steps and connect them into one Journey with Channels in the beginning and KPIs at the end</li>
      <li>Connect to <router-link to="/channels">Traffic Channels</router-link></li>
      <li>Connect to <router-link to="/links">Link Tree</router-link></li>
      <li>Connect to <router-link to="/kpis">KPI Tools</router-link></li>
      <li>Connect to <router-link to="/contents">Content Library</router-link></li>
    </ul>-->

    <div class="row justify-content-center mb-4">
      <div v-if="step >= 0" class="col-sm-11 col-md-7">
        <EditProgress :steps="editSteps" :step-index="step" @setStep="step = $event"></EditProgress>
      </div>
    </div>

    <div v-if="step === 0" class="card">
      <div class="card-header">
        <h3 class="mb-0">Deine neue Customer Journey</h3>
      </div>
      <div class="card-body">
        <h4>Der Weg ist das Ziel.</h4>
        <p>
          Deine Kunden landen nicht einfach gleich bei dem Produkt das sie suchen oder gar im Warenkorb. Sie stöbern
          vielleicht erst auf deiner Website herum, schauen sich verschiedene Produkte im Internet an und kehren dann
          zurück, wenn sie sich sicher sind. Und auch dann müssen sie noch zu deiner Zielseite gelangen.
        </p>
        <p>
          Eine Customer Journey ist wie ein Pfad, den ein Nutzer auf deiner Website entlang geht. Erstelle hier
          anhand deiner gescrapeten Seiten deine Customer Journey, wie du sie dir vorstellst. <br>
          Keine Sorge, du kannst später immer noch alles bearbeiten, du musst dich also nicht gleich komplett festlegen.
        </p>

        <h5>Benenne deine neue Customer Journey</h5>
        <p class="small">
          Benutze z.B. das Produkt oder die Dienstleistung, die am Ende gekauft werden soll als Titel.
        </p>
        <input type="text" v-model="newJourney.title" placeholder="Neue Journey benennen..." class="form-control">
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-sm-6"></div>
          <div class="col-sm-6">
            <button @click="step++" class="btn btn-block btn-primary">Zum nächsten Schritt</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="step === 1" class="card">
      <div class="card-header">
        <h3 class="mb-0">{{ newJourney.title }}</h3>
      </div>
      <div  class="card-body">
        <h4>Nun zu den Schritten deiner Customer Journey.</h4>
        <p>
          Wie gesagt, dein Kunde benötigt oft mehrere Schritte zum Ziel. Wähle hier die Seiten aus, die deine Nutzer
          durchlaufen (müssen), um an ihr Ziel zu kommen.
        </p>

        <div class="row">
          <div class="col-sm-6">
            <h5>Deine Seiten:</h5>
            <input v-model="search" class="form-control mb-3" placeholder="Seite suchen..." type="text">
            <NodeSelector :exclude-items="['all']"
                          :nodes="newNodes"
                          node-size="col-lg-6"
                          wrapperClasses="scroll-container-v"
                          @selectNode="selectNode($event)"
            ></NodeSelector>
          </div>
          <div class="col-sm-6">
            <div class="sticky-top">
              <h5 class="mb-5">Deine ausgewählten Schritte:</h5>
              <NodeSelector :exclude-items="['all']"
                            :nodes="selectedNodes"
                            node-size="col-lg-6"
                            @selectNode="unselectNode($event)"
              ></NodeSelector>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-sm-6">
            <button @click="step--" class="btn btn-block btn-secondary">Schritt zurück</button>
          </div>
          <div class="col-sm-6">
            <button @click="preSave()" class="btn btn-block btn-primary">Zum nächsten Schritt</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="step === 2" class="card">
      <div class="card-header">
        <h3 class="mb-0">{{ newJourney.title }}</h3>
      </div>
      <div class="card-body">
        <h4>Füge deiner Customer Journey weitere Infos hinzu</h4>
        <p>
          Die Schritte deiner Customer Journey werden hier angezeigt. <br>
          Du kannst nun zusätzliche Infos hinzufügen, um später noch besser einschätzen zu können, wo Potentiale
          sind und welchen Gedankengang die Nutzer in diesem Schritt haben könnten.
        </p>
        <div class="row flex-nowrap scroll-container-h pt-3">
          <div v-for="(step, index) in journeySteps" class="col-11" :key="index">
            <div class="card h-100">
              <div class="order-indicator">
                {{ index+1 }}
              </div>
              <div v-if="step.node" class="card-header">
                <h5>{{ step.node.title }}</h5>
                <p><code>{{ step.node.path || step.node.url }}</code></p>
                <p class="text-muted">{{ step.node.pagetype }}</p>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <img v-if="step.node" :src="getImg(step.node, 'desktop')" alt="" class="img-fluid">
                  </div>
                  <div class="col-6">
                    <h5>Name des Schritts</h5>
                    <input type="text" v-model="step.name" class="form-control">

                    <h6 class="mt-3">Kurze Beschreibung:</h6>
                    <textarea style="min-height: 4rem" v-model="step.description"
                              class="form-control"></textarea>
                    <hr>

                    <NodeEditor :node="step.node" :full-width="true"></NodeEditor>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-sm-6">
            <button @click="step--" class="btn btn-block btn-secondary">
              Schritt zurück
            </button>
          </div>
          <div class="col-sm-6">
            <button @click="save" class="btn btn-block btn-success">
              Customer Journey speichern
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import NodeSelector from "@/components/NodeSelector";
import EditProgress from "@/components/EditProgress";
import NodeEditor from "@/components/NodeEditor";

export default {
  name: 'JourneysEdit',
  components: {
    NodeEditor,
    EditProgress,
    NodeSelector
  },
  data() {
    return {
      step: 0,
      editSteps: [
        "Journey einrichten",
        "Schritte auswählen",
        "Schritte beschreiben"
      ],
      search: "",
      selectedNodes: [],
      newJourney: {
        id: "",
        title: "",
        steps: []
      },
      newStep: {
        nodeUrl: "",
        image: "",
        name: "",
        description: "",
        attributes: {},
        metrics: {}
      },
      newMetric: {
        id: this.generateId(),
        title: ""
      },
      newAttribute: {
        id: this.generateId(),
        title: ""
      },
    }
  },
  computed: {
    metrics() {
      return this.project.settings.nodeMetrics || [];
    },
    attributes() {
      return this.project.settings.nodeAttributes || [];
    },
    newNodes() {
      let searchNode = node => {
        let isSearched = this.search === "";
        if (!isSearched) {
          for (let prop of ['url', 'title']) {
            if (node[prop].indexOf(this.search) >= 0) {
              isSearched = true;
            }
          }
        }
        return isSearched;
      }
      return [{
        id: this.generateId(),
        url: "Platzhalter",
        title: "Leerer Schritt (füge hier eigene Seiten hinzu)",
        image: {
          thumb: "",
          desktop: "",
          tablet: "",
          mobile: ""
        }
      }]
          .concat(this.project.netNodes.filter(searchNode))
          .concat(this.project.externalNodes.filter(searchNode));
    },
    journeySteps() {
      return this.newJourney.steps.map(step => {
        step.node = this.findNode(step.nodeUrl);
        return step;
      });
    }
  },
  methods: {
    selectNode($event) {
      this.selectedNodes.push($event);
    },
    unselectNode($event) {
      let index = this.selectedNodes.findIndex(n => n.url === $event.url);
      this.selectedNodes.splice(index, 1);
    },
    createAttribute() {
      if (!this.project.settings.nodeAttributes) {
        this.project.settings.nodeAttributes = [];
      }
      this.project.settings.nodeAttributes.push(this.newAttribute);
      this.newAttribute = {
        id: this.generateId(),
        title: ""
      };
      this.$store.dispatch('project/updateProjectByProp', {prop: 'settings', data: this.project.settings})
          .then(res => {
            console.debug(res);
          });
    },
    createMetric() {
      if (!this.project.settings.nodeMetrics) {
        this.project.settings.nodeMetrics = [];
      }
      this.project.settings.nodeMetrics.push(this.newMetric);
      this.newMetric = {
        id: this.generateId(),
        title: ""
      };
      this.$store.dispatch('project/updateProjectByProp', {prop: 'settings', data: this.project.settings})
          .then(res => {
            console.debug(res);
          });
    },
    setMetric(nodeUrl, metric, $event) {
      let index = this.project.nodeMetrics.findIndex(n => n.url === nodeUrl);
      if (index >= 0) {
        this.project.nodeMetrics[index][metric.id] = $event.target.value;
      } else {
        let metrics = this.project.settings.nodeMetrics.reduce((acc, n) => {
          acc[n.id] = 0;
          return acc;
        }, { url: nodeUrl });
        metrics[metric.id] = $event.target.value;
        this.project.nodeMetrics.push(metrics);
      }
      this.$store.dispatch("project/updateProjectByProp", {prop: "nodeMetrics", data: this.project.nodeMetrics});
    },
    addAttribute(nodeUrl, attribute, $event) {
      if ($event.target.value === "") return;
      let index = this.project.nodeAttributes.findIndex(n => n.url === nodeUrl);
      if (index >= 0) {
        this.project.nodeAttributes[index][attribute.id].push($event.target.value);
      } else {
        let attributes = this.project.settings.nodeAttributes.reduce((acc, n) => {
          acc[n.id] = [];
          return acc;
        }, { url: nodeUrl });
        attributes[attribute.id].push($event.target.value);
        this.project.nodeAttributes.push(attributes);
      }
      this.$store.dispatch("project/updateProjectByProp", {prop: "nodeAttributes", data: this.project.nodeAttributes});
      $event.target.value = "";
    },
    removeAttribute(nodeUrl, attribute, index) {
      let aIndex = this.project.nodeAttributes.findIndex(n => n.url === nodeUrl);
      if (aIndex >= 0) {
        this.project.nodeAttributes[aIndex][attribute.id].splice(index, 1);
      }
      this.$store.dispatch("project/updateProjectByProp", {prop: "nodeAttributes", data: this.project.nodeAttributes});
    },
    preSave() {
      let index = 0;
      for (let node of this.selectedNodes) {
        let step;
        if (this.newJourney.steps[index]) {
          step = this.newJourney.steps[index];
          step.nodeUrl = node.url;
          this.newJourney.steps.splice(index, 1, step);
        } else {
          step = JSON.parse(JSON.stringify(this.newStep));
          step.nodeUrl = node.url;
          this.newJourney.steps.push(step);
        }
        index++;
      }
      this.newJourney.steps.splice(index, this.newJourney.steps.length - index);
      this.step++;
    },
    save() {
      if (this.$route.params.id) {
        let index = this.project.journeys.findIndex(a => a.id === this.newJourney.id);
        this.project.journeys.splice(index, 1, this.newJourney);
      } else {
        this.project.journeys.push(this.newJourney);
      }
      this.$store.dispatch('project/updateProjectByProp', {prop: 'journeys', data: this.project.journeys})
          .then(res => {
            console.debug(res);
            this.$router.push('/journeys');
          });
    },
    getJourney(id) {
      let journey = JSON.parse(JSON.stringify(this.project.journeys.find(j => j.id === id)));

      journey.steps.map(step => {
        step.node = this.findNode(step.nodeUrl);
        if (step.node.metrics) {
          step.metrics = step.node.metrics;
        } else {
          step.metrics = this.project.settings.nodeMetrics.reduce((acc, met) => {
            acc[met] = step.metrics[met.title] || 0;
            return acc;
          }, {});
        }
        if (step.node.attributes) {
          step.attributes = step.node.attributes;
        } else {
          step.attributes = this.project.settings.nodeAttributes.reduce((acc, att) => {
            acc[att] = step.attributes[att.title] || [];
            return acc;
          }, {});
        }
        return step;
      });

      return journey;
    }
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.newJourney = this.getJourney(this.$route.params.id);
      this.selectedNodes = this.newJourney.steps.map(step => step.node);
      this.step = 2;
    } else {
      this.newJourney.id = this.generateId();
      this.newStep.attributes = this.project.settings.nodeAttributes.reduce((acc, n) => {
        acc[n.id] = [];
        return acc;
      }, {});
      this.newStep.metrics = this.project.settings.nodeMetrics.reduce((acc, n) => {
        acc[n.id] = [];
        return acc;
      }, {});
    }
  }
}
</script>

<style lang="scss">
.scroll-container-v {
  max-height: 80vh;
  height: 30rem;
}
.order-indicator {
  position: absolute;
  top: -1rem;
  right: -1rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: var(--dark);
  text-align: center;
  font-size: 1rem;
  padding: .25rem;

  &, a { color: var(--white); }
}
</style>